/* eslint-disable import/prefer-default-export */

import { Cart, CartUpdateAction, DiscountedPrice, Order, Price } from '@commercetools/platform-sdk';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import compact from 'lodash/compact';
import sumBy from 'lodash/sumBy';
import { computed, Ref } from 'vue';
import { Router } from 'vue-router';
import { Store } from 'vuex';

import { fromAxiosNutsJson, webstore } from '@/api';
import cartApi from '@/api/cart';
import { GiftCertificateResponse } from '@/api/giftCertificates';
import { productImagesById } from '@/api/productImagesById';
import { productPathsById } from '@/api/productPathsById';
import { createApi } from '@/api/proxiedCommercetools';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { UseCallback, useCallback } from '@/composables/useCallback';
import { useState } from '@/composables/useState';
import { getCartDiscountCodes } from '@/lib/cart/discounts';
import { buildLineSavingsSummary, collapseChildren, NutsLineItem } from '@/lib/cart/lineItem';
import { productRemoved } from '@/rudder-typer';
import { ImageUrlsByProductId } from '@/store/modules/cart';
import { NutsAddress } from '@/utils/address';
import { formatProductRemoved } from '@/utils/analytics/rudderstack';
import {
  AddToCartPayload,
  AddToCartResponse,
  applyPriceTier,
  buildChangeLineItemQuantityActions,
  buildRemoveSpecialDeliveryAddressesActions,
  buildTitleImage,
  findListingImage,
  getCartId,
  isGiftLineItem,
  isGreetingCardLineItem,
  LineItemChanges,
} from '@/utils/cart';
import { Money } from '@/utils/money';
import { reportError } from '@/utils/reportError';
import { retry } from '@/utils/retry';

interface NutsCartCustomFields {
  asyncTaxNeeded?: boolean;
  orderTags?: string[];
  stashedDiscountCode?: string;
  stashedDiscountCodeName?: string;
  taxInputHash?: string;
}

interface AddedItem {
  id: string;
  quantity: number;
  isComplementaryItem: boolean;
}

interface AddedItemWithAddOns extends AddedItem {
  addOns: AddedItem[];
}

export function useCart(store: Store<any>, alternativeCart?: Ref<Cart | Order | undefined>) {
  const { applyChanges, inProgressChanges, pendingChanges } = useState('editableCart', () => ({
    applyChanges: undefined as undefined | UseCallback<void>,
    inProgressChanges: new Map<string, LineItemChanges>(),
    pendingChanges: new Map<string, LineItemChanges>(),
  }));

  const { isCartAddedOpen, itemsBeingAdded, pendingRequests, recentlyAddedItem } = useState(
    'cartAdded',
    () => ({
      isCartAddedOpen: false,
      itemsBeingAdded: [] as AddedItem[],
      pendingRequests: {} as Record<string, boolean>,
      recentlyAddedItem: {
        addOns: [] as AddedItem[],
        id: '',
        isComplementaryItem: false,
        quantity: 0,
      } as AddedItemWithAddOns | undefined,
    }),
  );

  const ct = createApi(webstore.sessionSpecific);

  const cart = computed<Cart | Order | undefined>(
    () => alternativeCart?.value ?? store.state.cartModule.rawCart,
  );

  const cartCopiedFromOrderNumber = computed<number | undefined>(
    () => store.state.cartModule.copiedFromOrderNumber ?? undefined,
  );
  const cartCopiedFromOrder = computed(() => !!cartCopiedFromOrderNumber.value);

  const cartCustomFields = computed<NutsCartCustomFields>(() => cart.value?.custom?.fields ?? {});

  const createdFromOrderUpload = computed(() =>
    cartCustomFields.value.orderTags?.includes('Uploaded'),
  );

  const cartDiscountCodes = computed(() => getCartDiscountCodes(cart.value, true));
  const pendingCartDiscountCodes = computed(() => getCartDiscountCodes(cart.value, false));

  const cartKey = computed(() => {
    if (!cart.value) return '';
    if (!('key' in cart.value)) return '';
    return cart.value?.key ?? '';
  });

  const customerEmail = computed(() => cart.value?.customerEmail);

  const customLineItems = computed(() => cart.value?.customLineItems ?? []);

  const listingImageUrlsByProductId = computed<ImageUrlsByProductId>(
    () => store.state.cartModule.listingImageUrlsByProductId,
  );

  const productUrlPathsById = computed<{ [productId: string]: string }>(
    () => store.state.cartModule.productPathsById,
  );

  const lineItems = computed<NutsLineItem[]>(() =>
    collapseChildren(
      cart.value?.lineItems?.map((lineItem) => ({
        ...NutsLineItem.fromCt(lineItem),
        productPath: productUrlPathsById.value[lineItem.productId],
        titleImage: buildTitleImage(lineItem, listingImageUrlsByProductId.value),
      })) ?? [],
    ),
  );

  const containsAutoDelivery = computed(() => {
    const autoDeliveryLineItems = lineItems.value.filter(
      (e) => !!e.custom?.fields?.autoDeliveryInterval,
    );
    return autoDeliveryLineItems.length > 0;
  });

  const containsProductDiscounts = computed(() =>
    lineItems.value.some((l) => l.totalSavings?.onSale),
  );

  const giftCertificate = computed<GiftCertificateResponse | undefined>(
    () => store.state.cartModule.giftCertificate ?? undefined,
  );

  // TODO: inline return as constant when Vuex store can be deprecated
  const maxQuantity = computed<number>(() => store.state.cartModule.maxQuantity);

  // A couple of helpers for assembling a Price with the correct tier selected,
  // and for keeping around the expanded ProductDiscount if possible. (During
  // optimisticLineItemUpdates operations, price may come from
  // `lineItem.variant.prices[]`, but
  // `lineItem.variant.prices[].discounted.discount` is not expanded. On the
  // other hand `lineItem.price.discounted.discount` is, so we can use that one
  // if it matches.)
  const retainDiscountObj = ({ discount }: DiscountedPrice, oldPrice: Price) =>
    !discount.obj && oldPrice.discounted?.discount.id === discount.id
      ? oldPrice.discounted.discount
      : discount;
  const reassemblePrice = (rawPrice: Price, quantity: number, oldPrice: Price): Price =>
    applyPriceTier(
      {
        ...rawPrice,
        discounted: rawPrice.discounted && {
          ...rawPrice.discounted,
          discount: retainDiscountObj(rawPrice.discounted, oldPrice),
        },
      },
      quantity,
    );

  const optimisticLineItemUpdates = computed<NutsLineItem[] | undefined>(() => {
    if (!inProgressChanges.value.size && !pendingChanges.value.size) {
      return undefined;
    }
    const adjustedLineItems = lineItems.value.map<NutsLineItem>((lineItem) => {
      const currentChanges = inProgressChanges.value.get(lineItem.id);
      const queuedChanges = pendingChanges.value.get(lineItem.id);
      if (!currentChanges && !queuedChanges) return lineItem;
      const changes = {
        ...currentChanges,
        ...queuedChanges,
      };

      const requireExternalPrice = lineItem.priceMode === 'ExternalPrice';
      const listPrice = requireExternalPrice
        ? lineItem.price
        : lineItem.variant.prices!.find((p) => !p.channel)!;
      let channelOrListPrice =
        lineItem.variant.prices!.find(
          (p) => p.channel?.obj?.key === lineItem.distributionChannel?.obj?.key,
        ) ?? listPrice;
      let { custom } = lineItem;
      if (changes.autoDeliveryInterval) {
        channelOrListPrice =
          lineItem.variant.prices!.find(
            (p) => p.channel?.obj?.key === changes.distributionChannelKey,
          ) ?? listPrice;

        if (!custom) {
          custom = {
            fields: {},
            type: { typeId: 'type', id: 'temporary-optimistic-replacement' },
          };
        }
        custom = {
          ...custom,
          fields: {
            ...custom.fields,
            autoDeliveryInterval: changes.autoDeliveryInterval,
          },
        };
      } else if (custom && changes.autoDeliveryInterval === null) {
        channelOrListPrice = listPrice;
        const { autoDeliveryInterval, ...fields } = custom.fields;
        custom = {
          ...custom,
          fields,
        };
      }

      if (requireExternalPrice) channelOrListPrice = listPrice;
      const quantity = changes.quantity ?? lineItem.quantity;
      const price = reassemblePrice(channelOrListPrice, quantity, lineItem.price);

      const discountedOrActivePrice = price.discounted ?? price;

      let children;
      if (lineItem.children) {
        children = lineItem.children.map<NutsLineItem>((child) => {
          const childPrice = reassemblePrice(
            child.variant.prices!.find((p) => !p.channel)!,
            quantity,
            child.price,
          );
          const piecePrice = childPrice.discounted?.value ?? childPrice.value;

          const newChild = { ...child, price: childPrice, quantity };
          return {
            ...newChild,
            piecePrice,
            totalPriceBeforeCartLevelDiscount: Money.multiply(piecePrice, quantity),
            totalSavings: buildLineSavingsSummary(newChild),
          };
        });
      }
      const piecePrice = isGiftLineItem(lineItem) ? from(0) : discountedOrActivePrice.value;

      const newLineItem = { ...lineItem, price, quantity };
      const updatedLineItem: NutsLineItem = {
        ...newLineItem,
        children,
        custom,
        piecePrice,
        totalPriceBeforeCartLevelDiscount: Money.multiply(piecePrice, quantity),
        totalSavings: buildLineSavingsSummary(newLineItem),
      };

      return children ? collapseChildren([updatedLineItem, ...children])[0] : updatedLineItem;
    });

    const adjustedIds = adjustedLineItems.map((l) => l.id);
    const filteredAdjustments = adjustedLineItems.filter((l) => l.quantity);

    return filteredAdjustments.length || lineItems.value.every((l) => adjustedIds.includes(l.id))
      ? filteredAdjustments
      : undefined;
  });

  /**
   * Pre-checkout line items
   *
   * Prioritize optimistic updates over the actual line items if they exist.
   *
   * NOT TO BE USED IN CHECKOUT CODE.
   */
  const preCheckoutLineItems = computed(() => optimisticLineItemUpdates.value ?? lineItems.value);

  const getItemFromList = (lineItem: NutsLineItem, quantity: number) => {
    const requireExternalPrice = lineItem.priceMode === 'ExternalPrice';
    const listPrice = requireExternalPrice
      ? lineItem.price
      : lineItem.variant.prices!.find((p) => !p.channel)!;

    let channelOrListPrice =
      lineItem.variant.prices!.find(
        (p) => p.channel?.obj?.key === lineItem.distributionChannel?.obj?.key,
      ) ?? listPrice;

    if (requireExternalPrice) channelOrListPrice = listPrice;

    const price = reassemblePrice(channelOrListPrice, quantity, lineItem.price);

    const discountedOrActivePrice = price.discounted ?? price;

    const piecePrice = isGiftLineItem(lineItem) ? from(0) : discountedOrActivePrice.value;

    const newLineItem = { ...lineItem, price, quantity };

    return {
      ...newLineItem,
      totalPriceBeforeCartLevelDiscount: Money.multiply(piecePrice, quantity),
      totalSavings: buildLineSavingsSummary(newLineItem),
    };
  };

  const addedLineItem = computed(() => {
    if (!recentlyAddedItem.value) return undefined;

    const lineItem = preCheckoutLineItems.value.find(
      (preCheckoutLineItem) => preCheckoutLineItem.id === recentlyAddedItem.value?.id,
    );

    if (!lineItem) return undefined;

    return getItemFromList(lineItem, recentlyAddedItem.value.quantity);
  });

  const addedAddOns = computed(
    () =>
      recentlyAddedItem.value?.addOns.map((addOn: AddedItem) => {
        const lineItem = preCheckoutLineItems.value.find(
          (preCheckoutLineItem) => preCheckoutLineItem.id === addOn.id,
        );

        if (!lineItem) return undefined;

        return getItemFromList(lineItem, addOn.quantity);
      }) ?? [],
  );

  const safcToken = computed<string | undefined>(
    () => store.state.cartModule.safcToken ?? undefined,
  );

  /** @deprecated use `shipments` or `standardShipments` instead */
  const shippingAddress = computed<NutsAddress | undefined>(() =>
    cart.value?.shippingAddress
      ? NutsAddress.fromCt({ ...cart.value.shippingAddress, key: 'shipment-1' })
      : undefined,
  );

  const unassignedLineItems = computed<NutsLineItem[]>(() =>
    lineItems.value
      .map((li) => {
        const assigned = sumBy(li.shippingDetails?.targets, (t) => t.quantity);
        const quantity = li.quantity - (assigned ?? 0);
        const totalPriceBeforeCartLevelDiscount = Money.multiply(
          li.totalPriceBeforeCartLevelDiscount,
          quantity / li.quantity,
        );
        const totalSavings = li.totalSavings && {
          ...li.totalSavings,
          comparisonPrice: Money.multiply(li.totalSavings.comparisonPrice, quantity / li.quantity),
          value: Money.multiply(li.totalSavings.value, quantity / li.quantity),
        };

        return {
          ...li,
          quantity,
          totalPriceBeforeCartLevelDiscount,
          totalSavings,
        };
      })
      .filter((lineItem) => lineItem.quantity > 0),
  );

  const fetchCartById = async (id: string) => {
    const { body } = await ct.carts().withId({ ID: id }).get().execute();
    return body;
  };

  const tryFetchCartById = async (id: string) => {
    try {
      const maybeCart = await fetchCartById(id);
      return maybeCart;
    } catch (e: any) {
      if ('statusCode' in e && e.statusCode === 404) return undefined;
      throw e;
    }
  };

  const loadCart = async (force = false) => {
    if (alternativeCart?.value && 'cartState' in alternativeCart.value) {
      // only if it's a Cart (vs Order)
      if (force) {
        // eslint-disable-next-line no-param-reassign
        alternativeCart.value = await tryFetchCartById(alternativeCart.value.id);
      }
    } else if (!store.state.cartModule.rawCart || force) {
      store.commit('cartModule/SET_RAW_CART', await tryFetchCartById('active-cart'));
    }
  };

  // support variants that don't have their own images
  const loadImagesForNonPrimaryVariants = async () => {
    const productIdsOfCartVariantsWithoutImages = new Set<string>();
    lineItems.value.forEach((lineItem) => {
      const { productId } = lineItem;
      if (!lineItem.variant.images?.length && !listingImageUrlsByProductId.value[productId]) {
        productIdsOfCartVariantsWithoutImages.add(productId);
      }
    });
    if (productIdsOfCartVariantsWithoutImages.size) {
      const productIds = Array.from(productIdsOfCartVariantsWithoutImages.values());
      const imagesById = await productImagesById(productIds);
      const imageUrlsByProductId: ImageUrlsByProductId = {};
      Object.entries(imagesById).forEach(([productId, images]) => {
        const bestImageForCheckout = findListingImage(images);
        if (bestImageForCheckout) {
          imageUrlsByProductId[productId] = bestImageForCheckout.url;
        }
      });
      store.dispatch('cartModule/cacheProductImageMap', imageUrlsByProductId);
    }
  };

  const loadProductUrlPaths = async () => {
    const ids = lineItems.value.reduce<string[]>((pendingIds, lineItem) => {
      if (!(lineItem.productId in productUrlPathsById.value)) {
        pendingIds.push(lineItem.productId);
      }
      return pendingIds;
    }, []);

    if (!ids.length) return;
    const pathsById = await productPathsById(ids);
    store.commit('cartModule/UPDATE_PRODUCT_PATHS_BY_ID', pathsById);
  };

  const loadLineItemExpansions = () =>
    Promise.all([loadImagesForNonPrimaryVariants(), loadProductUrlPaths()]);

  const loadSafcToken = (email?: string): Promise<void> =>
    store.dispatch('cartModule/fetchSafcToken', email);
  const setCopiedFromOrderNumber = (orderNumber: number | null): Promise<void> =>
    store.dispatch('cartModule/setCopiedFromOrderNumber', orderNumber);
  const setGiftCertificate = (maybeGiftCertificate?: GiftCertificateResponse): Promise<void> =>
    store.dispatch('cartModule/setGiftCertificate', maybeGiftCertificate);
  const setCart = (newCart?: Cart) => {
    if (alternativeCart?.value) {
      // eslint-disable-next-line no-param-reassign
      alternativeCart.value = newCart;
    } else {
      store.commit('cartModule/SET_RAW_CART', newCart);
    }
  };
  const setSafcToken = (token?: string) => store.commit('cartModule/SET_SAFC_TOKEN', token);

  async function retryCme<T>(fn: () => Promise<T>) {
    return retry(fn, {
      onError: (e) =>
        e.statusCode === 409 ||
        !!e.body?.message?.includes('The user aborted a request') ||
        !!e.body?.message?.includes('502 Bad Gateway'),
      reset: () => loadCart(true),
    });
  }

  async function tryUpdateCart(actions: CartUpdateAction[]) {
    if (!cart.value) {
      throw new Error("Can't update cart before loading");
    }
    if ('orderState' in cart.value) {
      throw new Error("Can't update order");
    }
    const interceptedActions: CartUpdateAction[] = actions.map((a) => {
      if (a.action === 'setLineItemShippingDetails') {
        const cleanedTargets =
          cart.value?.shippingMode === 'Single'
            ? a.shippingDetails?.targets.map(({ shippingMethodKey, ...t }) => ({ ...t }))
            : a.shippingDetails?.targets.map((t) => ({ ...t, shippingMethodKey: t.addressKey }));
        return {
          ...a,
          shippingDetails: {
            ...a.shippingDetails,
            targets: cleanedTargets ?? [],
          },
        };
      }
      return a;
    });
    const { id, version } = cart.value;
    const { body } = await ct
      .carts()
      .withId({ ID: id })
      .post({ body: { version, actions: interceptedActions } })
      .execute();
    setCart(body);
  }

  async function tryDeleteCart() {
    if (!cart.value) {
      throw new Error("Can't delete cart before loading");
    }
    const { id, version } = cart.value;
    await ct.carts().withId({ ID: id }).delete({ queryArgs: { version } }).execute();
    setCart(undefined);
  }

  type Optional<T> = T | false | null | undefined | 0 | '';
  async function updateCart(buildActions: () => Optional<CartUpdateAction>[] | null | undefined) {
    await loadCart();
    await retryCme(async () => {
      const actions = compact(buildActions());
      if (actions.length) {
        await tryUpdateCart(actions);
      }
    });
  }

  async function deleteCart() {
    await loadCart();
    await retryCme(async () => {
      await tryDeleteCart();
    });
  }

  const clearCart = () =>
    updateCart(() => {
      const actions = [
        ...customLineItems.value.map<CartUpdateAction>((customLineItem) => ({
          action: 'removeCustomLineItem',
          customLineItemId: customLineItem.id,
        })),
        ...(cart.value?.lineItems.map<CartUpdateAction>((lineItem) => ({
          action: 'removeLineItem',
          lineItemId: lineItem.id,
        })) ?? []),
      ];

      actions.push(
        ...buildRemoveSpecialDeliveryAddressesActions(
          cart.value?.itemShippingAddresses,
          lineItems.value,
        ),
      );

      if (cart.value?.custom?.fields.orderTags) {
        actions.push({ action: 'setCustomField', name: 'orderTags' });
      }
      return actions;
    });

  const adaptLineItemChangesToActions = (lineItemId: string, changes: LineItemChanges) => {
    if (!cart.value || !('cartState' in cart.value)) throw new Error('Cart cannot be modified');
    const lineItem = lineItems.value.find((item) => item.id === lineItemId);
    if (!lineItem) throw new Error(`Cannot find line item for ID ${lineItemId}`);

    const actions: CartUpdateAction[] = [];
    const {
      autoDeliveryInterval,
      autoDeliveryOfferLocation,
      autoDeliveryOfferType,
      greetingCardMessage,
      markedAsGift,
      quantity,
    } = changes;

    if (autoDeliveryInterval || autoDeliveryInterval === null) {
      if (
        !lineItem.custom?.fields.autoDeliveryOfferLocation ||
        autoDeliveryOfferLocation === null
      ) {
        actions.push({
          action: 'setLineItemCustomField',
          lineItemId,
          name: 'autoDeliveryOfferLocation',
          value: autoDeliveryOfferLocation,
        });
      }
      if (!lineItem.custom?.fields.autoDeliveryOfferType || autoDeliveryOfferType === null) {
        actions.push({
          action: 'setLineItemCustomField',
          lineItemId,
          name: 'autoDeliveryOfferType',
          value: autoDeliveryOfferType,
        });
      }
      actions.push(
        {
          action: 'setLineItemCustomField',
          lineItemId,
          name: 'autoDeliveryInterval',
          value: autoDeliveryInterval,
        },
        {
          action: 'setLineItemDistributionChannel',
          lineItemId,
          distributionChannel: changes.distributionChannelKey
            ? { key: changes.distributionChannelKey, typeId: 'channel' }
            : undefined,
        },
      );
    }

    if (isGreetingCardLineItem(lineItem) && 'greetingCardMessage' in changes) {
      actions.push({
        action: 'setLineItemCustomField',
        lineItemId,
        name: 'greetingCardMessage',
        value: greetingCardMessage || '',
      });
    }

    if (markedAsGift !== undefined) {
      actions.push({
        action: 'setLineItemCustomField',
        lineItemId,
        name: 'markedAsGift',
        value: markedAsGift,
      });
    }

    if (quantity !== undefined) {
      actions.push(...buildChangeLineItemQuantityActions(lineItem, quantity, cart.value));
    }

    return actions;
  };
  const flushPendingChanges = () =>
    pendingChanges.value.forEach((changes, lineItemId) => {
      inProgressChanges.value.set(lineItemId, {
        ...inProgressChanges.value.get(lineItemId),
        ...changes,
      });
      pendingChanges.value.delete(lineItemId);
    });
  // can be lost in hydration
  if (!applyChanges.value || !('execute' in applyChanges.value)) {
    applyChanges.value = useCallback(async () => {
      flushPendingChanges();
      if (!inProgressChanges.value.size) return;
      await updateCart(() => {
        const actions: CartUpdateAction[] = [];
        inProgressChanges.value.forEach((changes, lineItemId) => {
          actions.push(...adaptLineItemChangesToActions(lineItemId, changes));
        });
        return actions;
      });
      inProgressChanges.value.clear();
      await applyChanges.value?.execute();
    });
  }
  const updateLineItem = async (
    lineItemId: string,
    changes: LineItemChanges,
    updatedQuantitySubset?: number,
  ) => {
    // Map can be lost in hydration, but we currently only use client-side
    if (inProgressChanges.value.constructor !== Map) {
      inProgressChanges.value = new Map<string, LineItemChanges>();
    }
    if (pendingChanges.value.constructor !== Map) {
      pendingChanges.value = new Map<string, LineItemChanges>();
    }

    if (inProgressChanges.value.get(lineItemId)?.quantity === 0) return;

    pendingChanges.value.set(lineItemId, {
      ...pendingChanges.value.get(lineItemId),
      ...changes,
    });

    if (changes.quantity && updatedQuantitySubset) {
      if (recentlyAddedItem.value?.id === lineItemId) {
        recentlyAddedItem.value.quantity = updatedQuantitySubset;
      }

      recentlyAddedItem.value?.addOns.forEach((addOn: AddedItem) => {
        if (addOn.id !== lineItemId) return;
        // eslint-disable-next-line no-param-reassign
        addOn.quantity = updatedQuantitySubset;
      });
    }

    if (!inProgressChanges.value.size) {
      await applyChanges.value?.execute();
    }
    if (applyChanges.value?.error) {
      inProgressChanges.value.clear();
      pendingChanges.value.clear();
      throw applyChanges.value.error;
    }
  };
  const updateLineItemFailure = computed(() => applyChanges.value?.error);

  const removeLineItem = (lineItemId: string) => {
    try {
      const lineItem = lineItems.value.find((item) => item.id === lineItemId);
      if (lineItem) {
        const payload = formatProductRemoved(lineItem, getCartId(cart.value)!);
        productRemoved(payload);
      }
    } catch (error) {
      reportError(error);
    }
    return updateLineItem(lineItemId, { quantity: 0 });
  };

  interface AddToCartOptions {
    isComplementaryItem?: boolean;
    postAddToCartCallback?: Function;
    router?: Router;
    skipCartAdded?: boolean;
  }
  const addToCart = async (payload: AddToCartPayload, options?: AddToCartOptions) => {
    const { navigateTo } = useRouteChange(options?.router);
    pendingRequests.value[payload.cart_sku.sku_external_id] = true;

    try {
      await fromAxiosNutsJson<AddToCartResponse>(cartApi.addToCart(payload), {
        onMessages: 'default',
        onRedirect: async (url, _, data) => {
          if (data?.cart) {
            setCart(data.cart);
          }

          pendingRequests.value[payload.cart_sku.sku_external_id] = false;

          itemsBeingAdded.value.push({
            id: data?.cart_line?.id ?? '',
            quantity: data?.cart_line?.quantity_added ?? 0,
            isComplementaryItem: !!options?.isComplementaryItem,
          });

          if (
            !options?.skipCartAdded &&
            Object.values(pendingRequests.value).every((pending) => !pending)
          ) {
            const addedItem = itemsBeingAdded.value.find(
              (item: AddedItem) => !item.isComplementaryItem,
            );
            const addOns = itemsBeingAdded.value.filter(
              (item: AddedItem) => item.isComplementaryItem,
            );

            recentlyAddedItem.value = {
              id: addedItem?.id ?? '',
              quantity: addedItem?.quantity ?? 0,
              isComplementaryItem: false,
              addOns,
            };

            isCartAddedOpen.value = true;
            pendingRequests.value = {};
            itemsBeingAdded.value = [];
          }

          await (options?.postAddToCartCallback ?? navigateTo)(url);
        },
      });

      localStorage.removeItem('listMetadata');
    } catch (ex) {
      console.error('failed to execute add to cart:', ex);
      pendingRequests.value[payload.cart_sku.sku_external_id] = false;
    }
  };

  return {
    addedAddOns,
    addedLineItem,
    addToCart,
    cart,
    cartCopiedFromOrder,
    cartCopiedFromOrderNumber,
    cartCustomFields,
    cartDiscountCodes,
    cartKey,
    clearCart,
    containsAutoDelivery,
    containsProductDiscounts,
    createdFromOrderUpload,
    customerEmail,
    customLineItems,
    deleteCart,
    giftCertificate,
    isCartAddedOpen,
    lineItems,
    listingImageUrlsByProductId,
    loadCart,
    loadImagesForNonPrimaryVariants,
    loadLineItemExpansions,
    loadProductUrlPaths,
    loadSafcToken,
    maxQuantity,
    optimisticLineItemUpdates,
    pendingCartDiscountCodes,
    preCheckoutLineItems,
    productUrlPathsById,
    removeLineItem,
    safcToken,
    setCart,
    setCopiedFromOrderNumber,
    setCustomerEmail: async (email?: string) =>
      updateCart(() => [
        {
          action: 'setCustomerEmail',
          email: email!, // typedef is wrong; this field is actually optional so trick TS
        },
      ]),
    setGiftCertificate,
    setSafcToken,
    /** @deprecated use `shipments` or `standardShipments` instead */
    shippingAddress,
    unassignedLineItems,
    updateCart,
    updateLineItem,
    updateLineItemFailure,
  };
}
